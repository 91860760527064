import { Context } from '@nuxt/types'
import Http, { IHttp } from 'services/common/Http'
import NumberFormat, { INumberFormat } from 'services/common/NumberFormat'
import FieldValidator, { IFieldValidator } from 'services/common/FieldValidator'
import Toaster, { IToaster } from 'services/toast'
import NewsAdapter from 'services/news/NewsAdapter'
import Pagination from 'services/pagination'
import FooterService from 'services/footer'
import ProductHandler from 'services/product'
import FieldReplacer, { IFieldReplacer } from 'services/common/FieldReplacer'
import ContactsService from 'services/contacts'
import CheckoutService from 'services/cards/CheckoutService'
import Ip from 'services/common/Ip'
import HeaderBasket, { IBasketCalc } from 'services/basket/BasketСalculations'
import Banners from 'services/banners'
import Breadcrumbs from 'services/breadcrumbs'
import TextFormat, { ITextFormat } from 'services/common/TextFormat'
import HeadCreator from 'services/HeadCreator'
import FaqService from 'services/faq'
import Sort from 'services/sort'
import PriceService from 'services/product/PriceService'
import ReviewsAdapter from 'services/reviews'
import LocalStorageBasket from 'services/basket/localStorage'
import ProductAdapter from 'services/product/ProductAdapter'
import ProductInBasket from 'services/basket/Products'
import Cards from 'services/cards'
import Users, { IUsers } from 'services/user'
import GeocoderAdapter from 'services/geocoder/GeocoderAdapter'
import ErrorHandler, { IErrorHandler } from 'services/common/ErrorHandler'
import BasketSchedule, { IBasketSchedule } from 'services/basket/Schedule'
import GeoSuggest from 'services/geoSuggest'
import ProductsEvent from 'services/products'
import MindboxService from 'services/mindbox'
import SearchService from 'services/search'
import AnyQueryService from 'services/anyQuery'
import MapService from './map'
type commonServices = {
  http: IHttp
  numberFormat: INumberFormat
  validator: IFieldValidator
  replacer: IFieldReplacer
  ip: Ip
  textFormat: ITextFormat
  error: IErrorHandler
}

type basketServices = {
  calc: IBasketCalc
  schedule: IBasketSchedule
}

export type servicesType = {
  common: commonServices
  news: NewsAdapter
  pagination: Pagination
  toaster: IToaster
  footer: FooterService
  product: ProductHandler
  contacts: ContactsService
  checkout: CheckoutService
  basket: basketServices
  banners: Banners
  breadcrumbs: Breadcrumbs
  head: HeadCreator
  faq: FaqService
  sort: Sort
  price: PriceService
  reviews: ReviewsAdapter
  localStorageBasket: LocalStorageBasket
  productInBasket: ProductInBasket
  productAdapter: ProductAdapter
  cards: Cards
  user: IUsers
  geocoder: GeocoderAdapter
  geoSuggest: GeoSuggest
  productsEvents: ProductsEvent
  mindbox: MindboxService
  search: SearchService
  anyQuery: AnyQueryService
  map: MapService
}

const services = (ctx: Context): servicesType => ({
  common: {
    http: new Http(ctx.$axios),
    numberFormat: new NumberFormat(),
    validator: new FieldValidator(),
    replacer: new FieldReplacer(),
    ip: new Ip(),
    textFormat: new TextFormat(),
    error: new ErrorHandler(ctx.error),
  },
  news: new NewsAdapter(),
  pagination: new Pagination(),
  toaster: new Toaster(ctx.store),
  footer: new FooterService(),
  product: new ProductHandler(),
  contacts: new ContactsService(),
  checkout: new CheckoutService(),
  basket: {
    calc: new HeaderBasket(),
    schedule: new BasketSchedule(),
  },
  banners: new Banners(),
  breadcrumbs: new Breadcrumbs(),
  head: new HeadCreator(ctx.env, ctx.route),
  faq: new FaqService(),
  sort: new Sort(ctx.route),
  price: new PriceService(),
  reviews: new ReviewsAdapter(),
  localStorageBasket: new LocalStorageBasket(ctx.store),
  productInBasket: new ProductInBasket(ctx.store, ctx.$services),
  productAdapter: new ProductAdapter(),
  cards: new Cards(),
  user: new Users(),
  geocoder: new GeocoderAdapter(),
  geoSuggest: new GeoSuggest(),
  productsEvents: new ProductsEvent(ctx.store, ctx.$services),
  mindbox: new MindboxService(ctx.store),
  search: new SearchService(ctx.store),
  anyQuery: new AnyQueryService(ctx.store),
  map: new MapService(),
})

export default services
