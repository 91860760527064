import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IProduct } from 'types/DTO/products'
import { IEntityReview, IEntityReviewFull, IReview } from 'types/DTO/reviews'
import { object } from '~/mocks/products'
import { IBasketProduct } from 'types/DTO/basket'
import axios from 'axios'

export interface IProducts {
  findAll(params: IHttpParams): Promise<any>
  findById(id: number, params: IHttpParams): Promise<any>
  findPersonalRecProducts(params: IHttpParams): Promise<any>
  getReviews(params: IHttpParams): Promise<any>
  findAnalogs(id: number, params: IHttpParams): Promise<any>
  findRecommendedProducts(params: IHttpParams): Promise<any>
  findCategoryRecommendedProducts(params: IHttpParams): Promise<any>
  findBySlug(slug: string, category: string, params: IHttpParams): Promise<any>
  setReview(slug: string, params: IReview): Promise<any>
  findByQuery(query: string, params: IHttpParams): Promise<any>
  findPortalProducts(slug: string, params: IHttpParams): Promise<any>
  searchAutocompleteAll(searchString: string, params: IHttpParams): Promise<any>
  getSearchPageProducts(searchString: string, params: IHttpParams): Promise<any>
}

export default class Products implements IProducts {
  constructor(private http: IHttp) {}

  async findAll(params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.http.get('/api-v2/products', params)
  }

  async findPersonalRecProducts(params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.http.get('/api-v2/products/reco-personal', params)
  }

  async searchAutocompleteAll(searchString: string, params: IHttpParams): Promise<any> {
    return await axios.get(`https://autocomplete.diginetica.net/autocomplete`, {
      params: {
        st: searchString,
        apiKey: process.env.DIGINETICA_API_KEY,
        ...params,
      },
    })
  }
  async getSearchPageProducts(searchString: string, params: IHttpParams): Promise<any> {
    return await axios.get(`https://sort.diginetica.net/search`, {
      params: {
        st: searchString,
        apiKey: process.env.DIGINETICA_API_KEY,
        ...params,
      },
    })
  }

  async findById(id: number, params: IHttpParams): Promise<CommonResponse<IProduct>> {
    // return await this.http.get(`/product-api/view/${id}`, params || {})
    return await new Promise((resolve) =>
      resolve(object.products.data.filter((i: IProduct) => +i.id === id)[0]),
    )
  }

  async getReviews(params: IHttpParams): Promise<IHttpResponse<IEntityReviewFull[]>> {
    /* return await this.http.get(`/products/${slug}/reviews`, params || {}) */
    return await this.http.get('/review-api', params || {})
    // return await new Promise((resolve) => resolve(res.reviews))
  }

  async findAnalogs(slug: any, params: IHttpParams): Promise<CommonResponse<IProduct>> {
    return await this.http.get(`/api-v2/analogues/${slug}`, params || {})
  }

  async findRecommendedProducts(params: IHttpParams): Promise<any> {
    return await this.http.get(`api-v2/products/reco-similar-products`, params || {})
  }

  async findCategoryRecommendedProducts(params: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/products/reco-popular-products-by-category`, params || {})
  }

  async findBySlug(
    slug: string,
    category: string,
    params: IHttpParams,
  ): Promise<CommonResponse<IProduct>> {
    return await this.http.get(`/api-v2/products/${slug}/${category}`, params || {})
  }

  async setReview(slug: string, params: IEntityReview): Promise<any> {
    // return await this.http.post(`/products/${slug}/reviews`, { params })
    const formData = new FormData()
    for (const i in params) {
      // @ts-ignore
      formData.append(`Review[${i}]`, params[i as keyof IEntityReview])
    }
    return await this.http.post('/review-api/create/', formData, {})
  }

  async findByQuery(query: string, params: IHttpParams): Promise<any> {
    return await this.http.get(`product-api/autocomplete/?word=${query}`, params)
  }

  async findPortalProducts(slug: string, params: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/trade-name/${slug}`, params)
  }
}
