export default function (ctx) {
  ctx.$axios.onError((error) => {
    const code = error.code || error.response?.status
    console.log(code)

    if (code >= 500 || code === 'ERR_UNESCAPED_CHARACTERS') {
      ctx.redirect('/error')
    } else if (code === 401) {
      ctx.$auth.logout()
      console.log(ctx.$auth)
    } else if (code >= 400 || code < 500) {
      ctx.$services.common.error.onError(code)
    }

    if (code === 401) {
      ctx.$auth.logout()
      ctx.redirect('/')
    }
  })

  ctx.$axios.onRequest((config) => {
    const cookieSource = process.server
      ? config.headers?.common?.cookie
      : process.client
        ? document.cookie
        : ''
    if (cookieSource) {
      const cookies = parseCookies(cookieSource)
      if (cookies.mindboxDeviceUUID) {
        config.headers.common['X-deviceuuid'] = cookies.mindboxDeviceUUID
      }
    }
  })
}

function parseCookies(cookieString) {
  const cookies = {}
  if (cookieString) {
    cookieString.split(';').forEach(function (cookie) {
      const parts = cookie.split('=')
      const key = parts.shift().trim()
      cookies[key] = decodeURIComponent(parts.join('='))
    })
  }
  return cookies
}
