
import Vue, { PropType } from 'vue'
import {
  IProductSpecification,
  IProductProducer,
  IProduct,
  ISimilarTable,
} from 'types/DTO/products'

export default Vue.extend({
  name: 'RPHTableProduct',
  components: {},
  props: {
    productSpec: {
      type: Object as PropType<IProductSpecification>,
      default: () => ({}),
    },
    productProducer: {
      type: Object as PropType<IProductProducer>,
      default: () => {},
    },
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
})
