
import BackIcon from '~/assets/icons/back-arrow.svg?inline'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'cart-layout',
  components: {
    LazyHydrate,
    BackIcon,
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
  },
  data() {
    return {
      backNavigationMap: {
        '/delivery-reservation': '/delivery-pickup',
        '/pickup-checkout': '/delivery-reservation',
      },
    }
  },
  methods: {
    goBack() {
      const currentPath = this.$route.path
      const backPath = this.getBackPath(currentPath)

      this.$router.push(backPath)
    },
    getBackPath(currentPath) {
      return this.backNavigationMap[currentPath] || '/'
    },
  },
}
