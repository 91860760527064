import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IBasketProduct, IProductPrice, IBasketPrice } from 'types/DTO/basket'

export const state = () => ({
  isDelivery: true,
  products: [] as IBasketProduct[],
  availableProducts: [] as IBasketProduct[],
  remainsProducts: [] as IBasketProduct[],
  basketId: null,
  pharmacyId: null,
  basketProductsId: [] as string[],
  productsPrice: [] as IProductPrice[],
  productsInit: false as boolean,
  totalPrice: 0,
  discountPrice: 0,
  totalCount: 0,
  quantityError: false,
  delivery: null,
  remainsModalActive: false,
  authCartResult: {
    basketAvailablePoints: 0,
    basketEarnedPoints: 0,
    couponDiscount: 0,
    couponStatus: '',
    description: '',
  },
  cartPrice: {} as IBasketPrice,
})

export type stateT = ReturnType<typeof state>

// @ts-ignore
function debounce(func, wait) {
  let timeout: any

  return function (...args: any) {
    const later = () => {
      clearTimeout(timeout)
      // @ts-ignore
      func.apply(this, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setDiscountPrice(state, payload) {
    state.discountPrice = payload
  },
  setIsDelivery(state, payload) {
    state.isDelivery = payload
  },
  setProducts(state, payload) {
    state.products = payload
  },
  setCartPrice(state, payload) {
    state.cartPrice = payload
  },
  setAvailableProducts(state, payload) {
    state.availableProducts = payload
  },
  setRemainsProducts(state, payload) {
    state.remainsProducts = payload
  },
  setBasketId(state, payload) {
    state.basketId = payload
  },
  setPharmacyId(state, payload) {
    state.pharmacyId = payload
  },
  setBasketProductsId(state, payload) {
    state.basketProductsId = payload
  },
  setProductsPrice(state, payload) {
    state.productsPrice = payload
  },

  setQuantityError(state, payload) {
    state.quantityError = payload
  },

  setProductsInit(state, payload) {
    state.productsInit = payload
  },
  setDeliveryInfo(state, payload) {
    state.delivery = payload
  },
  deleteBasket(state) {
    state.availableProducts = []
    state.basketId = null
    state.basketProductsId = []
    state.products = []
    state.productsPrice = []
    state.totalPrice = 0
    state.totalCount = 0
    state.discountPrice = 0
    state.products = []
  },
  setProductCount(state, payload) {
    const product = state.products.find(
      (p) => p.id === payload.product_id && p.pharmacy_id === payload.pharmacy_id,
    )
    if (product) {
      product.count = +payload.count
    }
  },
  deleteProductById(state, payload) {
    const element = state.products.find((item: IBasketProduct) => item.id === payload)
    if (element) {
      const index = state.products.indexOf(element)
      state.products.splice(index, 1)
    }
  },
  setTotalPrice(state, payload) {
    state.totalPrice = payload
  },
  setTotalCount(state, payload) {
    state.totalCount = payload
  },

  setRemainsModal(state, payload) {
    state.remainsModalActive = payload
  },

  setAuthCartResult(state, payload) {
    state.authCartResult = payload
  },

  clearAuthCartPromo(state) {
    state.authCartResult = {
      basketAvailablePoints: state.authCartResult.basketAvailablePoints,
      basketEarnedPoints: state.authCartResult.basketEarnedPoints,
      couponDiscount: 0,
      couponStatus: '',
      description: '',
    }
  },
}

export const actions: ActionTree<stateT, stateT> = {
  setIsDelivery({ commit }, value) {
    commit('setIsDelivery', value)
  },
  async updateBasket({ commit, state }) {
    commit(
      'setAvailableProducts',
      this.$services.productInBasket.availableAdapt(state.products, state.isDelivery),
    )

    commit('setProductsPrice', this.$services.productInBasket.adaptPrice(state.availableProducts))
    commit(
      'setDiscountPrice',
      this.$services.basket.calc.priceDiscount(state.productsPrice, state.isDelivery),
    )
    commit('setTotalCount', this.$services.basket.calc.count(state.availableProducts))
    commit('setTotalPrice', this.$services.basket.calc.price(state.productsPrice, state.isDelivery))
    debounce(() => {
      if (state.products.length) {
        this.$services.mindbox.mindboxSetCart()
        this.$services.anyQuery.anyQuerySetCart()
      } else {
        this.$services.mindbox.mindboxClearCart()
      }
    }, 800)()
  },

  async getBasketById({ commit }, id: number) {
    try {
      const res = await this.$repositories.basket.findAll(id, {})
      return res.data
    } catch (e) {
      console.log('error: getBasketById', e)
    }
  },

  async createBasket({ commit, rootGetters }) {
    try {
      const userId = rootGetters['rph-users/user'].id
      const res = await this.$repositories.basket.createBasket(userId)
      commit('setBasketId', res.data.id)
      return res.data
    } catch (e) {
      console.log('error: createBasket', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка при создании корзины.',
      })
    }
  },

  async createPrefilledBasket({ commit }, params: IBasketProduct[]) {
    try {
      const res = await this.$repositories.basket.createPrefilledBasket(params)
      commit('setBasketId', res.data.id)
      localStorage.setItem('basketId', String(res.data.id))
      commit('setRemainsProducts', [])
      return res.data
    } catch (e) {
      console.log('error: createBasket', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка при создании корзины.',
      })
    }
  },

  async getBasketProducts({ commit, state }, { id, params }) {
    try {
      const res = await this.$repositories.basket.findAll(id, params)
      const pharmacyPrices = res.data?.pharmacyPrices
      const adaptData = this.$services.productInBasket.adapt(res.data)
      const availableAdaptData = this.$services.productInBasket.availableAdapt(
        adaptData,
        state.isDelivery,
      )
      const priceAdaptData = this.$services.productInBasket.adaptPrice(availableAdaptData)
      if (adaptData.length) {
        commit('setCartPrice', res.data.prices)
        commit('setProducts', adaptData)
        commit('setAvailableProducts', availableAdaptData)
        commit('setPharmacyId', adaptData?.[0].pharmacy_id ?? null)
        commit('setProductsPrice', priceAdaptData)
        commit('setTotalCount', this.$services.basket.calc.count(availableAdaptData))
        commit(
          'setDiscountPrice',
          this.$services.basket.calc.priceDiscount(priceAdaptData, state.isDelivery),
        )
        commit('setTotalPrice', this.$services.basket.calc.price(priceAdaptData, state.isDelivery))
        commit('setProductsInit', true)
        commit('setBasketProductsId', adaptData.map((item: any) => item.id).toString())
        if (pharmacyPrices && Object.keys(pharmacyPrices).length > 0) {
          commit('setDeliveryInfo', pharmacyPrices)
        }
      }
    } catch (e) {
      console.log('error: getBasketProducts', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка при загрузке списка покупок.',
      })
    }
  },

  async deleteById({ commit, state, dispatch }, { id, product_id, pharmacy_id, params }) {
    try {
      commit('deleteProductById', +product_id)
      await dispatch('updateBasket')
      const res = await this.$repositories.basket.deleteBasketItem(
        id,
        product_id,
        pharmacy_id,
        params,
      )
      // let result = await this.$repositories.basket.findAll(id, {})
      // commit('setCartPrice', result.data?.prices)
      commit('setProductsPrice', this.$services.productInBasket.adaptPrice(state.availableProducts))
      return res
    } catch (e) {
      await dispatch('getBasketProducts', {
        id: state.basketId,
        params: {
          params: {
            expand:
              'basketProducts,basketProducts.product,basketProducts.product.prices,basketProducts.product.images,basketProducts.product.category,basketProducts.product.specification,basketProducts.product.producers',
          },
        },
      })
      console.log('error: deleteById', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка при удалении товара.',
      })
    }
  },

  async addToBasket({ dispatch }, params: IBasketProduct) {
    try {
      await this.$repositories.basket.addToBasket(params)
      dispatch('updateBasket')
      return true
    } catch (e) {
      console.log('error: addToBasket', e)
    }
  },

  async changeCount({ commit, state, dispatch }, { id, product_id, pharmacy_id, params, val }) {
    try {
      const id = Number(state.basketId)
      commit('setProductCount', {
        product_id,
        pharmacy_id,
        count: val,
      })

      dispatch('updateBasket')
      await this.$repositories.basket.changeCount(id, product_id, pharmacy_id, params)
      // let result = await this.$repositories.basket.changeCount(id, product_id, pharmacy_id, params)
      // //@ts-ignore
      // commit('setCartPrice', result.data.basket.prices)
    } catch (e) {
      await dispatch('getBasketProducts', {
        id: state.basketId,
        params: {
          params: {
            expand:
              'basketProducts,basketProducts.product,basketProducts.product.prices,basketProducts.product.images,basketProducts.product.category,basketProducts.product.specification,basketProducts.product.producers',
          },
        },
      })
      console.log('error: changeCount', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка.',
      })
    }
  },

  async deleteBasket({ commit }, id) {
    try {
      return await this.$repositories.basket.deleteBasket(id)
    } catch (e) {
      console.log('error: deleteBasket', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка.',
      })
    }
  },

  async concatBasket({ commit }, { id, params }) {
    try {
      return await this.$repositories.basket.concatBasket(id, params)
    } catch (e) {
      console.log('error: concatBasket', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка. Получения корзины пользователя',
      })
    }
  },

  async calcBasketPoints({ commit }, promocode?: string) {
    try {
      commit('clearAuthCartPromo')
      const res = await this.$repositories.basket.calculateAuthorizedCart({}, promocode)
      commit('setAuthCartResult', {
        basketAvailablePoints: res.data.available_amount,
        basketEarnedPoints: res.data.earned_amount,
        couponDiscount: res.data.coupon_discount,
        couponStatus: res.data.coupon_status,
        description: res.data.coupon_description,
      })
    } catch (err) {
      console.log(err, 'calcBasketPoints')
    }
  },
}
export const getters: GetterTree<stateT, stateT> = {
  deliveryPage(state) {
    return state.isDelivery
  },
  basketList(state) {
    return state.products
  },
  cartPrice(state) {
    return state.cartPrice
  },
  discountPrice(state) {
    return state.discountPrice
  },
  availableProductsList(state) {
    return state.availableProducts
  },
  remainsProductsList(state) {
    return state.remainsProducts
  },
  basketId(state) {
    return state.basketId
  },
  pharmacyId(state) {
    return state.pharmacyId
  },
  basketProductsId(state) {
    return state.basketProductsId
  },
  productsPrice(state) {
    return state.productsPrice
  },
  productsInit(state) {
    return state.productsInit
  },
  totalPrice(state) {
    return state.totalPrice
  },
  totalCount(state) {
    return state.totalCount
  },
  quantityError(state) {
    return state.quantityError
  },
  deliveryInfo(state) {
    return state.delivery
  },
  remainsModalActive(state) {
    return state.remainsModalActive
  },
  authCartResult(state) {
    return state.authCartResult
  },
}
