import { Context } from '@nuxt/types'
import cookie from 'cookie'

// @ts-ignore
export default function ({ redirect, store, res }: Context) {
  if (!store.getters['rph-order/orderProducts'].length) {
    res.setHeader('Set-Cookie', [
      cookie.serialize('isRedirectedToCart', 'true', {
        maxAge: 60, // 30 минут
        path: '/',
        httpOnly: false,
      }),
    ])
    // @ts-ignore
    return redirect({ path: '/delivery', query: { reinitialization: true } })
  }
}
