export default class MapService {
  constructor() {}

  mergeBasketAndStocks(data) {
    const { stocks, basketProducts } = data

    const result = basketProducts.map((basketProduct) => {
      const stock = stocks.find((stockItem) => stockItem.product_id === basketProduct.product_id)
      return {
        ...basketProduct,
        stock: stock || {},
      }
    })

    return result
  }
}
