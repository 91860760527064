
import LazyHydrate from 'vue-lazy-hydration'
import RPHAd from 'rph/molecules/RPHAd/index.vue'

export default {
  name: 'Home',
  components: {
    LazyHydrate,
    RPHSearchBlock: () => import('rph/templates/RPHSearchBlock/index.vue'),
    RPHPopularProductBlock: () => import('rph/templates/RPHPopularProductBlock/index.vue'),
    RPHNearestProductsBlock: () => import('rph/templates/RPHNearestProductsBlock/index.vue'),
    RPHArticlesBlock: () => import('rph/templates/RPHArticlesBlock/index.vue'),
    RPHAboutBlock: () => import('rph/templates/RPHAboutBlock/index.vue'),
    RPHAd,
  },
  layout: 'main',
  async asyncData({ store }) {
    await Promise.allSettled([
      !Object.keys(store.state['rph-main'].mainPageSeo).length
        ? store.dispatch('rph-main/getMainPage')
        : '',
      !store.getters['rph-news/articles'].length
        ? store.dispatch('rph-news/getArticles', {
            expand: 'description,seo',
            page: 1,
            'per-page': 4,
            sort: '-created_at',
          })
        : '',
      !store.getters['rph-banners/largeBanners'].length
        ? store.dispatch('rph-banners/getLargeBanners')
        : '',

      !store.getters['rph-banners/smallBanners'].length
        ? store.dispatch('rph-banners/getSmallBanners')
        : '',

      !store.getters['rph-banners/hotBanners'].length
        ? store.dispatch('rph-banners/getHotBanners')
        : '',

      !store.getters['rph-products/popularProducts'].length
        ? store.dispatch('rph-products/getPopularProducts', {
            params: {
              initial: 'popular products',
              expand: 'annotation,specification,images,prices,seo,category,extra,producers',
              'per-page': 10,
              'filter[is_season]': 1,
              'filter[sort_stock]': 1,
            },
          })
        : '',

      !store.getters['rph-products/personalRecProducts'].length
        ? store.dispatch('rph-products/getPersonalRecProducts', {
            params: {
              expand: 'annotation,specification,images,prices,seo,category,extra,producers',
              'per-page': 10,
            },
          })
        : '',

      !store.getters['rph-categories/categories'].length
        ? store.dispatch('rph-categories/getCategories', { expand: 'child' })
        : '',
      !store.getters['rph-products/nearestProducts'].length
        ? store.dispatch('rph-products/getNearestProducts', {
            params: {
              expand: 'annotation,specification,images,prices,seo,category,producers',
              'per-page': 10,
              'filter[is_special]': 1,
              'filter[sort_stock]': 1,
            },
          })
        : '',
      !store.getters['rph-news/categories'].length
        ? store.dispatch('rph-news/getArticleCategories', {})
        : '',
    ])
  },
  head() {
    return {
      title: this.seo?.title,
      meta: this.$services.head.createMeta({
        title: this.seo?.title,
        description: this.seo?.description,
        keywords: this.seo?.keywords,
      }),
      link: [this.$services.head.createCanonicalLink(this.$route.path)],
    }
  },
  computed: {
    seo() {
      return this.$store.getters['rph-main/mainPageSeo']
    },
    mainPageSeoText() {
      return this.$store.getters['rph-main/mainPageSeoText']
    },
    products() {
      return this.$store.getters['rph-products/products']
    },
    hotBanners() {
      return this.$store.getters['rph-banners/hotBanners']
    },
    banner() {
      const banners = this.$services.banners.getBannersByLocation(
        this.hotBanners,
        this.$services.banners.locationHomeUnderSearch,
      )
      if (banners.length) {
        return banners[Math.floor(Math.random() * banners.length)]
      } else {
        return {}
      }
    },
    isMobileApp() {
      return this.$store.getters['rph-main/isMobileApp']
    },
  },
}
